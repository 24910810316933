import { Api } from 'common/server';
import { Page } from 'common/ui';
import moment from 'moment';

export class AgentReports {
    static inject = [Api, Page];
    _api;
    _page;

    reports = [];
    reportDate = moment();

    constructor(api, page) {
        this._api = api;
        this._page = page;
        this._initialize();
    }

    _initialize() {
        this.reports.push({ name: 'Simplified Submit Monthly Bonus', api: 'report/agent-monthly-bonus-export', working: false, useDate: true, model: {} });
        this.reports.push({ name: 'Agent Monthly Production', api: 'production/agent/submitted-production-export', working: false, useDate: true, model: { timePeriod: 'mtd' } });
        this.reports.push({ name: 'Agent YTD Production Summary', api: 'production/agent/ytd-production-summary-export', working: false, useDate: true, model: { timePeriod: 'ytd' } });
    }

    async exportReport(report) {
        try {
            const model = Object.assign(
                {},
                report.model,
                report.useDate ? { date: this.reportDate.format('MM/DD/YYYY') } : {},
            );
            report.working = true;
            await this._page.export(this._api, report.api, model);
        } catch (err) {
            console.log(err);
        } finally {
            report.working = false;
        }
    }
}
