import { observable } from 'aurelia-framework';
import { Api } from 'common/server';
import { Page } from 'common/ui';
import { Carriers } from 'services/carriers';
import { Products } from 'services/products';
import { Reports } from 'services/reports';
import { ProductionService } from 'services/production-service';

export class PolicyReports {
    static inject = [Api, Page, Carriers, Products, Reports, ProductionService];
    _api;
    _page;
    _carriers;
    _products;
    _reports;
    _productionService;

    reports = [];
    policyNumber;
    focusPolicyNumber = true;
    submittedRange;
    paidRange;
    updatedRange;
    writingAgent;
    agency;
    carriers;
    @observable carrierId;
    products;
    productId;
    status;
    statuses = [];

    pageSize = 10;

    constructor(api, page, carriers, products, reports, productionService) {
        this._api = api;
        this._page = page;
        this._carriers = carriers;
        this._products = products;
        this._reports = reports;
        this._productionService = productionService;
        this._initialize();
    }

    _initialize() {
        this.reports.push({ name: 'Export Policies', api: 'policy/export', working: false, usePolicyNumber: true, useSubmittedDates: true, usePaidDates: true, useUpdatedDates: true, useWritingAgent: true, useAgency: true, useCarrier: true, useProduct: true, useClient: true, useStatus: true, model: {} });
        this.reports.push({ name: 'Export Policies - Angie\'s Format', api: 'policy/export', working: false, usePolicyNumber: true, useSubmittedDates: true, usePaidDates: true, useUpdatedDates: true, useWritingAgent: true, useAgency: true, useCarrier: true, useProduct: true, useClient: true, useStatus: true, model: { format: 'commission' } });
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.statuses = await this._productionService.policyStatuses();
            this.carriers = await this._carriers.all();
        } catch (err) {
            console.log(err);
        }
    }

    async carrierIdChanged() {
        try {
            if (!this.carrierId) {
                this.products = undefined;
                return;
            }
            this.products = await this._products.list(this.carrierId);
        } catch (err) {
            console.log(err);
        }
    }

    async loadPolicies() {
        if (this.working) return;
        try {
            this.working = true;
            this.showList = true;
            const model = this._reportQuery(this.reports[0]);
            const data = await this._reports.policies(model);
            this.policies = data.policies;
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }

    async exportReport(report) {
        if (report.working) return;
        try {
            const model = this._reportQuery(report);
            report.working = true;
            await this._page.export(this._api, report.api, model);
        } catch (err) {
            console.log(err);
        } finally {
            report.working = false;
        }
    }

    _reportQuery(report) {
        const model = Object.assign(
            {},
            report.model,
            report.usePolicyNumber && this.policyNumber ? {
                policyNumber: this.policyNumber
            } : {},
            report.useSubmittedDates && this.submittedRange && this.submittedRange.length === 2 ? {
                submittedStartDate: this.submittedRange[0].format('MM/DD/YYYY'),
                submittedEndDate: this.submittedRange[1].format('MM/DD/YYYY')
            } : {},
            report.usePaidDates && this.paidRange && this.paidRange.length === 2 ? {
                paidStartDate: this.paidRange[0].format('MM/DD/YYYY'),
                paidEndDate: this.paidRange[1].format('MM/DD/YYYY')
            } : {},
            report.useUpdatedDates && this.updatedRange && this.updatedRange.length === 2 ? {
                updatedStartDate: this.updatedRange[0].format('MM/DD/YYYY'),
                updatedEndDate: this.updatedRange[1].format('MM/DD/YYYY')
            } : {},
            report.useWritingAgent && this.writingAgent ? {
                writingAgentId: this.writingAgent.id
            } : {},
            report.useAgency && this.agency ? {
                agencyId: this.agency.id
            } : {},
            report.useCarrier && this.carrierId ? {
                carrierId: this.carrierId
            } : {},
            report.useProduct && this.productId ? {
                productId: this.productId
            } : {},
            report.useClient && this.clientName ? {
                clientName: this.clientName
            } : {},
            report.useStatus && this.status ? {
                status: this.status
            } : {},
        );
        return model;
    }
}
