import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Security} from 'common/security';
import {Page} from 'common/ui';
import {QandA} from 'services/q-and-a';
import {AnswerEditor} from './dialog/answer-editor';
import {DialogService} from 'aurelia-dialog';
PLATFORM.moduleName('./dialog/answer-editor');

@inject(EventAggregator, Security, Page, QandA, DialogService)
export class Answers {

    slug = null;
    intros = [];

    constructor(eventAggregator, security, page, qAndA, dialogService) {
        this._ea = eventAggregator;
        this._security = security;
	    this._page = page;
	    this._qAndA = qAndA;
	    this.dialogService = dialogService;

	    this.isAdmin = this._security.isAdmin;
    }

    activate(params, routerConfig) {
        this._page.track('admin/answers');
        this._initialize(true);
    }

    attached() {
        var me = this;
	    this._initializeHandler = this._ea.subscribe('lpfn.answers.initialize', function(uiWidget) {
	        me._initialize(true);
	    });
	}

	detached() {
	    this._initializeHandler.dispose();
	}

	_initialize(clear) {
	    this._qAndA.initialize(clear).then(intros => {
	        this.intros = intros;
	    });
	}

	openAnswerEditor(intro) {
	    var me = this;
	    this.dialogService.open({ viewModel: AnswerEditor, model: intro, ordering: intro.ordering, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;

	        if (response.output.qAndADeleted) {
	            me.slug = null;
	        }
            
	        me.intros = [];
	        me._initialize(true);
	    });
	}

	openAddAnswer() {
	    var me = this;
	    var model = {
	        ordering: me.intros.length ? me.intros[me.intros.length-1].ordering + 1 : 1
	    };
	    this.dialogService.open({ viewModel: AnswerEditor, model: model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        me.intros = [];
	        me._initialize(true);
	    });
	}
}
