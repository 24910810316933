import { Api } from 'common/server';
import moment from 'moment';

export class AuditLogs {
    static inject = [Api];
	start;
	end;
	isProcessing = false;
    canNavigate = true;
    canGoNext = false;

    pageSize = 50;

	constructor(api) {
		this._api = api;
	}

	async run() {
        try {
            this.isProcessing = true;
            const qs = [];
            qs.push(`start=${this.start}`);
            if (this.end) qs.push(`end=${this.end}`);
            const url = `audit-log?${qs.join('&')}`;
            this.logs = await this._api.get(url);
        } catch (err) {
            console.log(err);
        } finally {
            this.isProcessing = false;
        }
	}

    activate() {
        this._initializing = true;
        this.start = moment().startOf('day').add(-1, 'days').format().datePickerDate();
        this.end = moment().startOf('day').add(-1, 'days').format().datePickerDate();
        this._setNavigation();
        this.run();
        this._initializing = false;
    }

    dateChanged() {
        if (this._initializing) return;
        if (!this.enterStartAndEnd) this.end = this.start;
        this._setNavigation();
        this.run();
    }

    _setNavigation() {
        this.canNavigate = !this.enterStartAndEnd;
        this.canGoNext = this.canNavigate && this.start && moment(this.start).startOf('day').isBefore(moment().startOf('day'));
    }

    previous() {
        if (!this.canNavigate) return;
        this._navigate(-1);
    }

    next() {
        if (!this.canGoNext || !this.canNavigate) return;
        this._navigate(1);
    }

    _navigate(days) {
        this.start = moment(this.start).add(days, 'days').format().datePickerDate();
        this.end = moment(this.end).add(days, 'days').format().datePickerDate();
        this.run();
        this._setNavigation();
    }
}
