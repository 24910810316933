import { observable } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Sites } from 'services/sites';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import environment from '../../../../config/environment.json';

export class SiteThemeEditor {
    static inject = [DialogController, Sites, NewInstance.of(ValidationController)];
    dialogController;
    _sites;
    _validationController;

    siteName;
    @observable domain;
    primaryColor;
    secondaryColor;
    highlightColor;
    boxBackgroundColor;

    showDeleteConfirm = false;
    deleteTextKey = 'delete';

    constructor(dialogController, sites, validationController) {
        this.dialogController = dialogController;
        this._sites = sites;
        this._validationController = validationController;
		this._validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('siteName').required()
            .ensure('domain').required()
            .ensure('email').required().email()
            .on(this);
    }

    async activate(model) {
        try {
            model = model || {};
            this.domain = model.domain;
            this.isNew = this.domain ? false : true;
            if (!this.isNew) {
                await this._load();
            }
            this._setImageUploadUrls();
            this._validationController.reset();
        } catch (err) {
            console.log(err);
        }
    }

    async _load() {
        try {
            if (!this.domain) return;
            this.theme = await this._sites.themeByDomain(this.domain);
            this.siteName = this.theme.siteName;
            this.email = this.theme.email;
            this.primaryColor = this.theme.primaryColor;
            this.secondaryColor = this.theme.secondaryColor;
            this.highlightColor = this.theme.highlightColor;
            this.boxBackgroundColor = this.theme.boxBackgroundColor;
            this.logoPath = `${environment.static}/${this.theme.logoPath}`;
            this.logoLightPath = `${environment.static}/${this.theme.light.logoPath}`;
            this.logoDarkPath = `${environment.static}/${this.theme.dark.logoPath}`;
            this.logoIconPath = `${environment.static}/${this.theme.logoIconPath}`;
            this.splashLogoPath = `${environment.static}/${this.theme.splash.logoPath}`;
            this.splashBackgroundImagePath = `${environment.static}/${this.theme.splash.backgroundImagePath}`;
            this.faviconFilePath = `${environment.static}/${this.theme.faviconFilePath}`;
        } catch (err) {
            console.log(err);
        }
    }

    domainChanged() {
        this._setImageUploadUrls();
    }

    _setImageUploadUrls() {
        this.logoUploadUrl = `${environment.api}/api/site/themes/upload/logo?domain=${encodeURIComponent(this.domain)}`;
        this.logoLightUploadUrl = `${environment.api}/api/site/themes/upload/logo-light?domain=${encodeURIComponent(this.domain)}`;
        this.logoDarkUploadUrl = `${environment.api}/api/site/themes/upload/logo-dark?domain=${encodeURIComponent(this.domain)}`;
        this.logoIconUploadUrl = `${environment.api}/api/site/themes/upload/logo-icon?domain=${encodeURIComponent(this.domain)}`;
        this.splashLogoUploadUrl = `${environment.api}/api/site/themes/upload/splash-logo?domain=${encodeURIComponent(this.domain)}`;
        this.backgroundImageUploadUrl = `${environment.api}/api/site/themes/upload/bg?domain=${encodeURIComponent(this.domain)}`;
        this.faviconsUploadUrl = `${environment.api}/api/site/themes/upload/favicons?domain=${encodeURIComponent(this.domain)}`;
    }
    
    async save() {
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;

            if (this.isNew) {
                const result = await this._sites.addTheme(this.domain, this.siteName, this.email, this.primaryColor, this.secondaryColor, this.highlightColor, this.boxBackgroundColor);
                this.isNew = false;
                this._load();
                return;
            } else {
                const result = await this._sites.updateTheme(this.domain, this.siteName, this.email, this.primaryColor, this.secondaryColor, this.highlightColor, this.boxBackgroundColor);
            }
            this.dialogController.ok();
        } catch (err) {
            console.log(err);
        }
    }

    async fileUploaded(fileType) {
        try {
            await this._load();
            switch (fileType) {
                case 'logo':
                    this.logoPath = `${environment.static}/${this.theme.rootPath}/${this.theme.logoName}`;
                    break;
                case 'logo-light':
                    this.logoLightPath = `${environment.static}/${this.theme.rootPath}/${this.theme.light.logo}`;
                    break;
                case 'logo-dark':
                    this.logoDarkPath = `${environment.static}/${this.theme.rootPath}/${this.theme.dark.logo}`;
                    break;
                case 'logo-icon':
                    this.logoIconPath = `${environment.static}/${this.theme.rootPath}/${this.theme.logoIcon}`;
                    break;
                case 'splash-logo':
                    this.splashLogoPath = `${environment.static}/${this.theme.rootPath}/${this.theme.splash.logoName}`;
                    break;
                case 'bg':
                    this.splashBackgroundImagePath = `${environment.static}/${this.theme.rootPath}/${this.theme.splash.backgroundImageName}`;
                    break;
                case 'favicons':
                    this.faviconLogoPath = `${environment.static}/${this.theme.rootPath}/${this.theme.faviconFilePath}`;
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteTextKey = 'delete';
    }

    async deleteSiteTheme() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteTextKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._sites.deleteTheme(this.domain);
            this.dialogController.ok({ themeDeleted: true });
        } catch(err) {
            this.resetDelete();
            console.log(err);
        } finally {
            this.deleting = false;
        }
    }
} 

 