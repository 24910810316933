import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import {I18n} from 'common/i18n';
import {QandA} from 'services/q-and-a';
import {c} from 'common/common';

@inject(DialogController, NewInstance.of(ValidationController), I18n, QandA)
export class AnswerEditor {

    id = null;
    slug = '';
    ordering = null;
    isActive = false;
    showDeleteConfirm = false;
    canEditSlug = false;

    constructor(dialogController, validationController, i18n, qAndA) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._qAndA = qAndA;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('slug').required().matches(c.RegEx.slug)
            .ensure('ordering').required().satisfiesRule('integerRange', 1, 5000)
            .on(this);
    }

    activate(model) {
        this.id = null;
        this.slug = '';
        this.ordering = model.ordering || 1;
        this.isActive = false;
        this.title = this._i18n.tr('q-and-a-add-title');
        if (model) {
            this.title = this._i18n.tr('q-and-a-edit-title');
            this.id = model.id;
            this.slug = model.slug;
            this.ordering = model.ordering;
            this.isActive = model.isActive;
        }

        this.canEditSlug = !this.id;
        this.validationController.reset();
    }

    save() {
        var me = this;
        this.validationController.validate().then(v => {
            if (!v.valid) return;

            me._qAndA.saveQandA(me.id, me.slug, me.ordering, me.isActive).then(() => {
                me.dialogController.ok({ qAndADeleted: false });
            }).catch(error => { });
        });
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    deleteQandA() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;

        this._qAndA.deleteQandA(this.id).then(() => {
            this.dialogController.ok({ qAndADeleted: true });
        }).catch(error => { });
    }
}
