import { PLATFORM } from 'aurelia-pal';
import { observable } from 'aurelia-framework';
import { Notifier } from 'common/ui';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { ProductionService } from 'services/production-service';
import { DialogService } from 'aurelia-dialog';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { SplitPolicy } from './dialogs/split-policy';
import { c } from 'common/common';
import moment from 'moment';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./dialogs/split-policy');

export class EditPolicy {
    static inject = [Notifier, Security, Api, NewInstance.of(ValidationController), ProductionService, DialogService];
    _notifier;
    _security;
    _api;
    validationController;
    _productionService;
    _dialogService;

    policyNumber;

    @observable selectedPolicy;
    policy;

    statuses;
    status;
    subStatuses;
    subStatus;

    submittedDate;
    paidDate;
    chargebackDate;
    canceledDate;
    closedDate;
    signedDate;
    finalDispositionDate;
    issuedDate;
    coverageTerminationDate;
    nextDraftDate;
    effectiveDate;

    submittedPremium;
    targetPremium;
    paidPremium;
    fullAnnualizedSubmittedPremium;
    fullAnnualizedPaidPremium;

    agencyMember;

    isProcessing = false;

    tab;
    TAB = {
        setDates: 'set-dates',
        setPremium: 'set-premium',
        setAgency: 'set-agency',
        setStatus: 'set-status'
    };

    imoSources = ['lpfn', 'other-imo'];

    constructor(notifier, security, api, validationController, productionService, dialogService) {
        this._notifier = notifier;
        this._security = security;
        this._api = api;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._productionService = productionService;
        this._dialogService = dialogService;

        this.tab = this.TAB.setDates;

        ValidationRules
            .ensure('submittedDate').required()
            .ensure('submittedPremium').satisfiesRule('decimalRange', 0, 999999999).when(m => m.tab === m.TAB.setPremium)
            .ensure('targetPremium').satisfiesRule('decimalRange', 0, 999999999).when(m => m.tab === m.TAB.setPremium)
            .ensure('paidPremium').satisfiesRule('decimalRange', 0, 999999999).when(m => m.tab === m.TAB.setPremium)
            .ensure('fullAnnualizedSubmittedPremium').satisfiesRule('decimalRange', 0, 999999999).when(m => m.tab === m.TAB.setPremium)
            .ensure('fullAnnualizedPaidPremium').satisfiesRule('decimalRange', 0, 999999999).when(m => m.tab === m.TAB.setPremium)
            .ensure('status').required().when(m => m.tab === m.TAB.setStatus)
            .on(this);
    }

    attached() {
        this._load();
    }

    activate(model) {
        if (model.id) {
            if (c.RegEx.guid.test(model.id)) {
                // TODO: pass GUID to get the policy number
                this.loadPolicy(model.id);
            } else {
                this.findPolicyNumber = model.id;
            }
        }
    }

    async _load() {
        try {
            this.statuses = await this._productionService.policyStatuses();
            this.subStatuses = await this._productionService.policySubStatuses();
        } catch (err) {
            console.log(err);
        }
    }

    selectedPolicyChanged() {
        if (!this.selectedPolicy) {
            this.policy = undefined;
            return;
        }
        this.loadPolicy();
    }

    async loadPolicy(id) {
        try {
            if (!this.selectedPolicy && !id) return;
            const loadPolicyId = this.selectedPolicy ? this.selectedPolicy.id : id;
            this.policy = await this._productionService.policy(loadPolicyId);
            this.imoSource = this.policy.imoSource;
            this.submittedDate = this._datePickerDate(this.policy.submittedDate);
            this.paidDate = this._datePickerDate(this.policy.paidDate);
            this.chargebackDate = this._datePickerDate(this.policy.chargebackDate);
            this.canceledDate = this._datePickerDate(this.policy.canceledDate);
            this.closedDate = this._datePickerDate(this.policy.closedDate);
            this.signedDate = this._datePickerDate(this.policy.signedDate);
            this.issuedDate = this._datePickerDate(this.policy.issuedDate);
            this.coverageTerminationDate = this._datePickerDate(this.policy.coverageTerminationDate);
            this.nextDraftDate = this._datePickerDate(this.policy.nextDraftDate);
            this.effectiveDate = this._datePickerDate(this.policy.effectiveDate);
            this.status = undefined;
            this.subStatus = undefined;

            this.submittedPremium = this.policy.submittedPremium;
            this.targetPremium = this.policy.targetPremium;
            this.paidPremium = this.policy.paidPremium;
            this.fullAnnualizedSubmittedPremium = this.policy.fullAnnualizedSubmittedPremium;
            this.fullAnnualizedPaidPremium = this.policy.fullAnnualizedPaidPremium;
        } catch (err) {
            this.policy = undefined;
            this._notifier.error(`Policy ${this.policyNumber} not found for ${this.carrier.name}`);
        }
    }

    _datePickerDate(value) {
        if (!value) return null;
        return new Date(value).toISOString().substring(0, 10);
    }

    displayTab(tab) {
        this.tab = tab;
    }

    async saveDates() {
        if (this.isProcessing) return;

        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.isProcessing = true;
            const model = {
                imoSource: this.imoSource,
                submittedDate: this._apiDate(this.submittedDate),
                paidDate: this._apiDate(this.paidDate),
                chargebackDate: this._apiDate(this.chargebackDate),
                canceledDate: this._apiDate(this.canceledDate),
                closedDate: this._apiDate(this.closedDate),
                signedDate: this._apiDate(this.signedDate),
                issuedDate: this._apiDate(this.issuedDate),
                coverageTerminationDate: this._apiDate(this.coverageTerminationDate),
                nextDraftDate: this._apiDate(this.nextDraftDate),
                effectiveDate: this._apiDate(this.effectiveDate),
            };

            await this._api.post(`policy/${encodeURIComponent(this.policy.id)}/set-dates`, model);
            this._notifier.success('Dates set');
            this.loadPolicy();
        } catch (err) {
            console.log(err);
            this._notifier.error('Error updating dates');
        } finally {
            this.isProcessing = false;
        }
    }

    async updatePremium() {
        if (this.isProcessing) return;

        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            this.isProcessing = true;
            const model = {
                submittedPremium: this._premiumValue(this.submittedPremium),
                targetPremium: this._premiumValue(this.targetPremium),
                paidPremium: this._premiumValue(this.paidPremium),
                fullAnnualizedSubmittedPremium: this._premiumValue(this.fullAnnualizedSubmittedPremium),
                fullAnnualizedPaidPremium: this._premiumValue(this.fullAnnualizedPaidPremium),
            };
            await this._api.post(`policy/${encodeURIComponent(this.policy.id)}/set-premiums`, model);
            this._notifier.success('Premiums Set');
            this.loadPolicy();
        } catch (err) {
            console.log(err);
            this._notifier.error('Error updating policies');
        } finally {
            this.isProcessing = false;
        }
    }

    async saveStatus() {
        if (this.isProcessing) return;

        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.isProcessing = true;
            const model = {
                carrierId: this.policy.carrier.id,
                policyNumber: this.policy.policyNumber,
                status: this.status,
                subStatus: this.subStatus || null
            };

            await this._api.post(`policy/${encodeURIComponent(this.policy.id)}/set-status`, model);
            this._notifier.success('Status Set');
            this.loadPolicy();
        } catch (err) {
            this._notifier.error('Error updating policy status');
        } finally {
            this.isProcessing = false;
        }
    }

    async removeStatusOverride() {
        if (this.isProcessingRemove) return;
        try {
            await this._productionService.removeStatusOverride(this.policy.id);
            this._notifier.success('Status Override Removed');
            this.loadPolicy();
        } catch (err) {
            console.log(err);
        } finally {
            this.isProcessingRemove = false;
        }
    }

    async removeSubStatus() {
        if (this.removingSubStatus) return;
        try {
            this.removingSubStatus = true;
            await this._productionService.removeSubStatus(this.policy.id);
            this._notifier.success('Sub Status Removed');
            this.loadPolicy();
        } catch (err) {
            console.log(err);
        } finally {
            this.removingSubStatus = false;
        }
    }

    async setAgency() {
        if (this.isProcessing) return;

        try {
            if (!this.agencyMember) return;
            this.isProcessing = true;
            const model = {
                carrierId: this.carrier.id,
                policyNumber: this.policyNumber,
                baseshopMemberId: this.baseshopMember.id,
            };

            await this._api.post('production/set-policy-baseshop', model);
            this._notifier.stickySuccess('Updated baseshop');
        } catch (err) {
            this._notifier.error('Error updating baseshop');
        } finally {
            this.isProcessing = false;
        }
    }

    _premiumValue(value) {
        if (value === 0) return 0;
        if (!value) return null;
        return value;
    }

    _apiDate(date) {
        if (!date) return null;
        if (!moment(date).isValid()) return null;
        return moment.utc(date).toISOString();
    }

    async recalculateCredits() {
        try {
            await this._api.post(`policy/${encodeURIComponent(this.policy.id)}/recalculate-credits`);
            this._notifier.success(`Policy ${this.policy.policyNumber} credits have been recalculated`);
            this.loadPolicy();
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        }
    }

    deletePolicy() {
        const model = { key: 'delete-policy', okButtonClass: 'btn-danger', messageObject: { policyNumber: this.policy.policyNumber } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
            await this._deletePolicy();
        });
    }

    async _deletePolicy() {
        try {
            await this._api.delete(`policy/${encodeURIComponent(this.policy.id)}`);
            this._notifier.success(`Policy ${this.policy.policyNumber} has been deleted`);
            this.selectedPolicy = undefined;
        } catch (err) {
            console.log(err);
            this._notifier.error(err);
        }
    }

    splitPolicy() {
        const model = { policyId: this.policy.id };
	    this._dialogService.open({ viewModel: SplitPolicy, model, ignoreTransitions: true }).whenClosed(async(response) => {
            this.loadPolicy();
        });
    }
}
