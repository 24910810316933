import { PLATFORM } from 'aurelia-pal';
import { SiteThemeEditor } from './dialog/site-theme-editor';
import { DialogService } from 'aurelia-dialog';
import { Sites } from 'services/sites';
import environment from '../../../config/environment.json';
PLATFORM.moduleName('./dialog/site-theme-editor');

export class SiteList {
    static inject = [Sites, DialogService]
    _sites;
    _dialogService;
    _ea;

    loading = false;

    siteThemes = [];

    constructor(sites, dialogService) {
        this._sites = sites;
        this._dialogService = dialogService;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.loading = true;
            this.siteThemes = await this._sites.themes();
            this.siteThemes.forEach(st => st.logoPathFull = `${environment.static}/${st.logoPath}`);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async openEditor(theme) {
        await this._dialogService.open({ viewModel: SiteThemeEditor, model: { domain: theme ? theme.domain : null } || {}, ignoreTransitions: true }).whenClosed(async(response) => {
            this.siteThemes = [];
            await this._load();
        });
        return false;
    }

    async toggleActive(siteTheme) {
        try {
            siteTheme.isActive = !siteTheme.isActive;
            await this._sites.setThemeActive(siteTheme.domain, siteTheme.isActive);
        } catch (err) {
            console.log(err);
        }
    }
}
