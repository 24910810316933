import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { PLATFORM } from 'aurelia-pal';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';

export class Reports extends RouterConfigBase {
	static inject = [EventAggregator, Security, Menus];

    constructor(ea, security, menus) {
        super(ea, security, menus, undefined, false);
    }
    
    configureRouter(config, router){
        config.auth = true;
        config.map([
                {
                    route: ['', 'agent-reports'],
                    moduleId: PLATFORM.moduleName('admin/reports/agent-reports'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Agent Reports', description: 'Export bonus and production summaries' },
                },
                {
                    route: 'agency-reports',
                    moduleId: PLATFORM.moduleName('admin/reports/agency-reports'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Agency Reports', description: 'Export agency bonus reports' },
                },
                {
                    route: 'level-changed',
                    moduleId: PLATFORM.moduleName('admin/reports/system-events/level-changed'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Level Change Reports', description: 'Export level changes' },
                },
                {
                    route: 'products',
                    moduleId: PLATFORM.moduleName('admin/reports/product-reports'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Product Reports', description: 'Export all products' },
                },
                {
                    route: 'policies',
                    moduleId: PLATFORM.moduleName('admin/reports/policy-reports'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Policy Reports', description: 'Find and export policies' },
                },
                {
                    route: 'audit',
                    moduleId: PLATFORM.moduleName('admin/reports/audit-logs/audit-logs'),
                    nav: true,
                    membersOnly: true,
                    adminOnly: true,
                    settings: { title: 'Audit Logs', description: 'View changes to policies and agents' },
                }
        ]);

        this.router = router;
    }

    attached() {
        this._attached();
    }

    detached() {
        this._detached();
    }
}
