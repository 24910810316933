import {inject} from 'aurelia-framework';
import {Api} from 'common/server';
import {Page} from 'common/ui';

@inject(Api, Page)
export class TeamMembers {

	constructor(api, page) {
		this.api = api;
		this.page = page;
	}

	exportAll() {
	    this.page.exportData(this.api, 'admin/export-members-key', 'admin/export-members', null)
            .then(response => { /*nothing...already handled*/ })
            .catch(error => { console.log(error); });
	}
}
