import { PLATFORM } from 'aurelia-pal';
import { Api } from 'common/server';
import { Notifier } from 'common/ui';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { AssignRoles } from './dialogs/assign-roles';
import { SetLevel } from './dialogs/set-level';
import { ChangeParent } from './dialogs/change-parent';
import { InactivateMember } from './dialogs/inactivate-member';
import { ResetPasswordNow } from './dialogs/reset-password-now';
import { SetMentor } from './dialogs/set-mentor';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
PLATFORM.moduleName('./dialogs/assign-roles');
PLATFORM.moduleName('./dialogs/set-level');
PLATFORM.moduleName('./dialogs/change-parent');
PLATFORM.moduleName('./dialogs/inactivate-member');
PLATFORM.moduleName('./dialogs/reset-password-now');
PLATFORM.moduleName('./dialogs/set-mentor');
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');

export class TeamMember {
    static inject = [Router, Api, Notifier, DialogService];
    memberId = null;
    isActive = true;

	constructor(router, api, notifier, dialogService) {
		this.router = router;
		this.api = api;
		this.notifier = notifier;
        this._dialogService = dialogService;
	}

	activate(params) {
	    this.memberId = params.id;
	    this.loadMember();
	}

	async loadMember() {
        try {
    	    const data = await this.api.get(`member/${this.memberId}`);
			this.fullName = data.fullName;
	        this.isActive = data.isActive;
	    } catch (err) {
	        this.notifier.generalError();
	    }
	}

	async tryDelete() {
        const model = { key: 'delete-agent', okButtonClass: 'btn-danger', messageObject: { name: this.fullName } };
	    this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
	        if (response.wasCancelled) return;
			try {
				this.showLoader = true;
				await this.api.delete(`member/${this.memberId}`);
				// go back to the search page
				return this.router.navigate('team-members', true);
			} catch (err) {
				if (err) this.notifier.error(err);
				else this.notifier.generalError();
			} finally {
				this.showLoader = false;
			}
        });
	}

    openDialog(type) {
        const model = { memberId: this.memberId };
        let viewModel;
        switch (type) {
            case 'assign-roles': viewModel = AssignRoles; break;
            case 'set-level': viewModel = SetLevel; break;
            case 'change-parent': viewModel = ChangeParent; break;
            case 'inactivate-member': viewModel = InactivateMember; break;
            case 'reset-password': viewModel = ResetPasswordNow; break;
			case 'mentor': viewModel = SetMentor; break;
            default: return;
        }
	    this._dialogService.open({ viewModel, model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this.loadMember();
	    });
    }
}
