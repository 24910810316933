import { Api } from 'common/server';
import { Page } from 'common/ui';

export class ProductReports {
    static inject = [Api, Page];
    _api;
    _page;

    constructor(api, page) {
        this._api = api;
        this._page = page;
    }

    async exportReport() {
        try {
            this.working = true;
            await this._page.export(this._api, 'product/report/list-export');
        } catch (err) {
            console.log(err);
        } finally {
            this.working = false;
        }
    }
}
