import { inject } from 'aurelia-framework';
import { Api } from 'common/server';
import { Page } from 'common/ui';

@inject(Api, Page)
export class LevelChanged {

	startDate = '';
	endDate = '';
	isProcessing = false;

	constructor(api, page) {
		this.api = api;
		this.page = page;
	}

	async exportLevelChangedEvents() {
        try {
            this.isProcessing = true;
            let theStartDate = null;
            let theEndDate = null;
            if (this.startDate) theStartDate = this.startDate.format('YYYY-MM-DD');
            if (this.endDate) theEndDate = this.endDate.format('YYYY-MM-DD');
            const postData = {
                startDate: theStartDate,
                endDate: theEndDate
            };
            await this.page.exportData(this.api, 'system-events/export-level-changes-key', 'system-events/export-level-changes', postData);
        } catch (err) {
            console.log(err);
        } finally {
            this.isProcessing = false;
        }
	}
}
