import { observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Page } from 'common/ui';
import { ProductionService } from 'services/production-service';
import { Carriers } from 'services/carriers';
import moment from 'moment';
import { c } from 'common/common';

export class StatusChanges {
    static inject = [EventAggregator, Api, Page, ProductionService, Carriers];
    _ea;
    _api;
    _page;
    _production;
    _carriers;

    @observable forDate = moment();
    carriers;
    @observable carrierId;

    policyFilters = [
        { value: '', keys: ['c.code', 'policyNumber', 'writingAgentMember.fullName', 'agency.fullName', 'statusValue', 'insuredFullName'] },
        { value: 'nucs', custom: this.policyChangeFilter },
    ];
    changedPolicyFilters = [{ value: '', keys: ['c.code', 'policyNumber', 'writingAgentMember.fullName', 'agency.fullName', 'statusValue', 'insuredFullName']}];
    @observable policyStatusN = true;
    @observable policyStatusU = true;
    @observable policyStatusC = true;
    @observable policyStatusS = true;

    constructor(ea, api, page, production, carriers) {
        this._ea = ea;
        this._api = api;
        this._page = page;
        this._production = production;
        this._carriers = carriers;
	}

    async activate() {
        this.report = undefined;
        this.carriers = await this._carriers.all();
    }

    attached() {
        this._attached = true;
        this._load();
    }

    forDateChanged(newValue, oldValue) {
        if (!this.forDate || !this._attached) return;
        console.log('FOR DATE CHANGED');
        if (newValue && oldValue) {
            if (moment(newValue).isSame(moment(oldValue))) return;
        }
        this._load();
    }

    async carrierIdChanged() {
        if (!this._attached) return;
        console.log('CARRIER ID CHANGED');
        this._load();
    }

    async _load() {
        try {
            console.log('LOADING');
            this.loading = true;
            this.report = await this._production.policyStatusChanges(this.forDate, this.carrierId);
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    policyStatusNChanged() {
        this._policyStatusChanged('n');
    }

    policyStatusUChanged() {
        this._policyStatusChanged('u');
    }

    policyStatusCChanged() {
        this._policyStatusChanged('c');
    }

    policyStatusSChanged() {
        this._policyStatusChanged('s');
    }

    _policyStatusChanged(policyChange) {
        if (this.policyFilters[1].value.indexOf(policyChange) >= 0) {
            this.policyFilters[1].value = this.policyFilters[1].value.replace(policyChange, '');
        } else {
            this.policyFilters[1].value += policyChange;
        }
    }

    policyChangeFilter(filterValue, row) {
        if (!filterValue) return false;
        if (filterValue.indexOf('n') >= 0 && row.isNewPolicy) return true;
        if (filterValue.indexOf('u') >= 0 && !row.isNewPolicy && row.isPolicyChanged) return true;
        if (filterValue.indexOf('c') >= 0 && !row.isPolicyChanged) return true;
        if (filterValue.indexOf('s') >= 0 && row.isSplit) return true;
        return false;
    }

    openPolicy(policyId) {
        this._ea.publish(c.EventKeys.site.openPolicy, { policyId: policyId });
    }

    async exportStatusChanges() {
        const policyIds = [];
        this.report.statusChanges.forEach(p => policyIds.push(p.policy.id));
        const model = { format: 'commission', policyIds };
        await this._page.export(this._api, 'policy/export', model);
    }
}
