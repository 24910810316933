import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {Page} from 'common/ui';

@inject(EventAggregator, Api, Page)
export class TeamMemberHierarchy {

	memberId = null;
	profile = null;
	fullName = '';

	constructor(eventAggregator, api, page) {
		this.eventAggregator = eventAggregator;
		this.api = api;
		this.page = page;
	}

	activate(params, routerConfig) {
		this.memberId = params.id;
		this.loadMember();
		this.page.scrollToTop();
	}

	loadMember() {
		var self = this;
		self.api.getData("member/" + this.memberId).then(memberProfile => {
			self.profile = memberProfile;
			self.fullName = memberProfile.firstName + ' ' + memberProfile.lastName;
		}).catch(error => { });
	}

	showProfile() {
		this.eventAggregator.publish("lpfn.show-member-profile-popup", { member: this.profile, memberId: this.memberId });
	}

}
