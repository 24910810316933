import {inject} from 'aurelia-framework';
import {Redirect} from 'aurelia-router';
import {Page} from 'common/ui';
import {QandA} from 'services/q-and-a';

@inject(Page, QandA)
export class Answer {

    slug;
    question;
    answerUiWidgetKey;
    qAndA;

    constructor(page, qAndA) {
        this._page = page;
        this._qAndA = qAndA;
    }

    activate(params, routerConfig) {
        this.slug = params.slug || null;
        if (!this.slug) return new Redirect('#admin/answers');
        this._page.track('admin/answers/' + this.slug);
        this._load();
    }

    _load() {
        this._qAndA.getIntro(this.slug).then(intro => {
            this.qAndA = intro;
            this.question = intro.question;
            this.answerUiWidgetKey = intro.answerUiWidgetKey;
            console.log(this.qAndA);
        });
    }
}
