import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { ProductionService } from 'services/production-service';
import { Notifier } from 'common/ui';

export class SplitPolicy {
    static inject = [DialogController, NewInstance.of(ValidationController), ProductionService, Notifier]

    _production;
    _notifier;

    splitMember;
    splitPercentage = 50;

    constructor(dialogController, validationController, production, notifier) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._production = production;
        this._notifier = notifier;

        ValidationRules
            .ensure('splitMember').required()
            .ensure('splitPercentage').required().satisfiesRule('integerRange', 0, 100)
            .on(this);
    }

    activate(model) {
        this.policyId = model.policyId;
        this.validationController.reset();
    }

    async split() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            if (!this.splitMember) return;
            await this._production.splitPolicy(this.policyId, this.splitMember.id, this.splitPercentage);
            this.dialogController.ok();
        } catch (err) {
            if (err) this._notifier.error(err);
            else this._notifier.generalError();
            console.log(err);
        }
    }
}