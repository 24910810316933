import { observable } from 'aurelia-framework';
import { Api } from 'common/server';
import { Page } from 'common/ui';
import { ProductionService } from 'services/production-service';
import { TIME_PERIOD, CREDIT_TYPE, CHART_COLORS } from 'common/constants';
import { mode } from 'common/ui';
import moment from 'moment';

export class AgencyReports {
    static inject = [Api, Page, ProductionService];
    _api;
    _page;
    _productionSvc;

    reports = [];

    reportMonth = moment().format();

    monthLineDatasets;
    creditTypes = CREDIT_TYPE.all();
    @observable viewCreditType = CREDIT_TYPE.SubmittedActual;

    constructor(api, page, productionSvc) {
        this._api = api;
        this._page = page;
        this._productionSvc = productionSvc;

        this._initialize();
    }

    _initialize() {
        this.reports.push({ name: 'Agency Monthly Bonus (original)', api: 'report/agency-monthly-bonus-export', working: false, useDate: true, model: {} });
        this.reports.push({ name: 'Agency Monthly Bonus 2 (50k min; 50% max per leg; downline subtracted)', api: 'report/agency-monthly-bonus2-export', working: false, useDate: true, model: {} });
        this.reports.push({ name: 'Agency Monthly Production', api: 'production/agency/submitted-production-export', working: false, useDate: true, model: { timePeriod: TIME_PERIOD.MTD } });
        this.reports.push({ name: 'Agency Last 6-Month Production Summary', api: 'production/agency/submitted-production-export', working: false, useDate: true, model: { timePeriod: TIME_PERIOD.SixMonthRolling } });
        //this.reports.push({ name: 'Agency Last 6-Month Production by Month', api: 'production/agency/monthly-production-export', working: false, useDate: true, model: { timePeriod: TIME_PERIOD.SixMonthRolling } });
        this.reports.push({ name: 'Agency YTD Production', api: 'production/agency/submitted-production-export', working: false, useDate: true, model: { timePeriod: TIME_PERIOD.YTD } });
    }

    attached() {
        this._loadMonthlyProduction();
    }

    viewCreditTypeChanged() {
        this._loadMonthlyProduction();
    }

    async _loadMonthlyProduction() {
        try {
            if (!this._monthlyProductionData) {
                this._monthlyProductionData = await this._productionSvc.agencyMonthlyProduction(TIME_PERIOD.SixMonthRolling);
            }
            const monthLineDatasets = [];
            const uiMode = mode();
            const colors = CHART_COLORS.Classic(uiMode);
            let ctr = 0;
            this._monthlyProductionData.agencies.forEach(record => {
                const data = [];
                record.months.forEach(monthRecord => {
                    const firstOfMonth = moment.utc(monthRecord.month.endOffset).startOf('month');
                    const premium = monthRecord.premiums.find(x => x.creditType === this.viewCreditType) ?? { premium: 0 };
                    data.push({ date: firstOfMonth.toISOString(), value: premium.premium });
                });
                monthLineDatasets.push({
                    key: record.agency.fullName,
                    data: data,
                    color: colors[ctr++],
                });
            });
            this.monthLineDatasets = monthLineDatasets;
        } catch (err) {
            console.log(err);
        }
    }

    async exportReport(report) {
        try {
            const model = Object.assign(
                {},
                report.model,
                report.useDate ? { date: this.reportMonth.format('MM/DD/YYYY') } : {},
            );
            report.working = true;
            await this._page.export(this._api, report.api, model);
        } catch (err) {
            console.log(err);
        } finally {
            report.working = false;
        }
    }
}
